import {t} from "i18next";

import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import ImgBox from "../../../ImgBox/ImgBox";
import CommonKitSampleActionPart from "../../CommonKitSampleActionPart/CommonKitSampleActionPart";

const SampleActionCase = ({
  images = [],
  onLeftBtnClick = () => {},
  onRightBtnClick = () => {},
  leftBtnName = "",
  isLeftBtnDisabled = false,
  title = "",
  children,
  nearImgPart,
}) => {
  return (
    <div className="h100 fcCol aic gap5 jcsb">
      <h1 className="centered mb">{t(title)}</h1>
      <div className="fc nowrap gap">
        {nearImgPart ?? ""}
        <div className="sample_photo_container" style={{width: "205px", height: "205px"}}>
          <ImgBox imgs={images} width={"205px"} height={"205px"} />
        </div>
      </div>

      <CommonKitSampleActionPart />
      <div className="w100 fcCol gap5 fGrow jcfe">{children}</div>

      <div className="row jcsa gap w100 mt">
        <FunctionButton
          name={leftBtnName}
          className="bigBtn"
          onClick={onLeftBtnClick}
          style={{height: "3rem"}}
          disabled={isLeftBtnDisabled}
        />
        <FunctionButton name="Back" className="bigBtn" onClick={onRightBtnClick} style={{height: "3rem"}} />
      </div>
    </div>
  );
};

export default SampleActionCase;
