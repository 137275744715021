export const ACTION_TYPES = {
  takeToReport: "takeToReport",
  returnFromReport: "returnFromReport",
  giveToReport: "giveToReport",
  restoreFromReport: "restoreFromReport",
  toWarehouse: "toWarehouse",
  inventory: "inventory",
  forWriteOffWarehouse: "forWriteOffWarehouse",
  takeToReportRM: "takeToReportRM",
  forWriteOff: "forWriteOff",
};

export const NO_NOTE_ACTIONS = [ACTION_TYPES.restoreFromReport, ACTION_TYPES.returnFromReport];
