import React, {useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {
  fetchMySamples,
  getCurrentEntity,
  getMyCurrentSample,
  getMySamples,
  getTableLoading,
  setMyCurrentSampleObj,
} from "../../slices/SampleSlice";
import {getCurrentUser} from "../../slices/UserSlice";
import {dateFormat} from "../../utilities/dateFormat";
import ReactTable from "../containers/ReactTable";

export const SKU_TABLE_VARIANTS = Object.freeze({SKU_LIST_PAGE: "skuListPage", RETURN_FORM: "returnForm"});

const SkuTable = ({selectable = true, variant = SKU_TABLE_VARIANTS.SKU_LIST_PAGE}) => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(getCurrentUser);
  const currentEntity = useSelector(getCurrentEntity);
  const currentSample = useSelector(getMyCurrentSample);
  const mySamples = useSelector(getMySamples);
  const loading = useSelector(getTableLoading);

  useEffect(() => {
    if (currentUser?.userId && variant === SKU_TABLE_VARIANTS.SKU_LIST_PAGE)
      dispatch(fetchMySamples({statusId: 3, managerId: currentUser.userId}));

    if (currentEntity?.managerId && currentUser?.userId && variant === SKU_TABLE_VARIANTS.RETURN_FORM)
      dispatch(
        fetchMySamples({
          statusId: 3,
          managerId: currentEntity.managerId,
          brandManagerId: currentUser.userId,
        }),
      );
  }, [currentUser?.userId, currentEntity?.managerId]);

  const mySamplesListData = useMemo(() => {
    if (variant === SKU_TABLE_VARIANTS.RETURN_FORM && !currentEntity) return [];

    const myFilteredSamples =
      variant === SKU_TABLE_VARIANTS.RETURN_FORM
        ? mySamples.filter(sample => sample.sampleId !== currentEntity.sampleId)
        : mySamples;

    return (
      myFilteredSamples?.map((samp, i) => ({
        number: i + 1,
        sku: {text: samp.skuNumber, isCursive: !!samp?.kitId},
        dateTo: {text: samp.dateTo, isCursive: !!samp?.kitId},
        id: samp.sampleId,
      })) || []
    );
  }, [mySamples, currentEntity?.skuNumber]);

  const columns = [
    {
      header: "№",
      accessorKey: "number",
      size: 25,
    },
    {
      header: t("SKU №"),
      accessorKey: "sku",
      cell: value => {
        var sku = value.getValue();
        return <div style={sku.isCursive ? {fontStyle: "italic"} : {}}>{sku.text}</div>;
      },
    },
    {
      header: t("Дата ДО:"),
      accessorKey: "dateTo",
      cell: value => {
        var sku = value.getValue();
        return <div style={sku.isCursive ? {fontStyle: "italic"} : {}}>{dateFormat(sku.text)}</div>;
      },
    },
  ];

  const onSelect = useCallback(
    id => {
      if (id === currentSample?.sampleId && variant === SKU_TABLE_VARIANTS.SKU_LIST_PAGE) {
        dispatch(setMyCurrentSampleObj(null));
        return;
      }
      const findSample = mySamples.find(samp => samp.sampleId === id);
      if (findSample) {
        dispatch(setMyCurrentSampleObj(findSample));
      }
    },
    [mySamples, currentSample],
  );

  return (
    <>
      <ReactTable
        defaultData={mySamplesListData}
        columns={columns}
        current={(selectable && currentEntity?.sampleId) || null}
        onSelect={onSelect}
        loading={loading}
        selectable={selectable}
        style={{height: "350px"}}
      />
    </>
  );
};

export default SkuTable;
