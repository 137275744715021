import React from "react";

import logo from "../Login/src/Logo.png";
import {Outlet} from "react-router-dom";

const AppLogo = () => {
  return (
    <div>
      <div className="app__logo">
        <img alt="" src={logo} draggable="false" />
      </div>
      <Outlet />
    </div>
  );
};

export default AppLogo;
