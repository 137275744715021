import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import ReturnIcon from "../../../images/icons/return.png";
import {cleanUpCurrent} from "../../../slices/SampleSlice";
import ImageButton from "../../../utilities/Buttons/ImageBtn";
import {ROUTES} from "../../../utilities/routes";
import Logo from "../../Login/src/Logo.png";

const ActionsNavPanel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onReturnBtnClick = () => {
    dispatch(cleanUpCurrent());
    navigate(ROUTES.SCANER);
  };
  function onLogoBtnClick() {}

  return (
    <>
      <div className="app__logo left">
        <ImageButton src={ReturnIcon} onClick={onReturnBtnClick} width={1.5} height={2.5} />
      </div>
    </>
  );
};

export default ActionsNavPanel;
