import {useLocation} from "react-router-dom";

import ActionsNavPanel from "../ActionsNavPanel/ActionsNavPanel";
import {ACTION_TYPES} from "../constants";

const withActionsDependent = Component => {
  const Result = props => {
    const {pathname} = useLocation();

    const actionType = ACTION_TYPES[pathname.slice(pathname.lastIndexOf("/") + 1)];

    return (
      <>
        {/* <ActionsNavPanel /> */}
        <Component actionType={actionType} {...props} />
      </>
    );
  };

  return Result;
};

export default withActionsDependent;
