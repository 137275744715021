import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import moment from "moment";

import {apiUrl} from "../api/baseURL";
import {fetchData} from "../api/utils/axios.hook";
import {getError, getInfo} from "../utilities/toasts";

const scannedSamples = sessionStorage.getItem("scanned_samples");
const currentActionType = sessionStorage.getItem("ActionType");
const initialState = {
  currentSample: null,
  current: null,
  mySamples: [],
  kits: [],
  tableLoading: false,
  locationHistory: [],
  scannedSamples: JSON.parse(scannedSamples) ?? [],
  currentActionType: currentActionType,
  loading: false,
  error: null,
};

export const getSample = createAsyncThunk("sample/getSample", async sampleId => {
  const responce = fetchData(`/samples/get/${sampleId}`, "get");
  return responce;
});

export const fetchByQR = createAsyncThunk("sample/fetchByQR", async url => {
  const replacedDomenUrl = url.replace(url.split("/")[2], apiUrl.split("/")[2]);
  return fetchData(replacedDomenUrl, "get");
});

export const takeUnderReport = createAsyncThunk("sample/takeUnderReport", async ({sampleId, data}) => {
  return fetchData(`/samples/takeUnderReport/${sampleId}`, "patch", data);
});

export const takeKitUnderReport = createAsyncThunk("sample/takeKitUnderReport", async ({kitId, data}) => {
  return fetchData(`/kits/takeUnderReport/${kitId}`, "patch", data);
});

export const returnToStock = createAsyncThunk("sample/returnToStock", async sampleId => {
  return fetchData(`/samples/returnToStock/${sampleId}`, "patch");
});
export const returnKitToStock = createAsyncThunk("sample/returnKitToStock", async kitId => {
  return fetchData(`/kits/returnToStock/${kitId}`, "patch");
});
export const fetchMySamples = createAsyncThunk("sample/fetchMy", async params => {
  return fetchData(`/samples/getAll`, "get", {params});
});
export const fetchKits = createAsyncThunk("sample/fetchMyKit", async params => {
  return fetchData(`/kits/getAll`, "get", {params});
});

//////images

export const addNewImage = createAsyncThunk("funcSamples/addNewSampleImages", async ({type, id, formdata}) => {
  const response = fetchData(`/${type}s/images/add/${id}`, "post", formdata);
  return response;
});

export const deleteImage = createAsyncThunk("funcSamples/deleteSampleImage", async ({type, imageId}) => {
  const response = fetchData(`/${type}s/images/remove/${imageId}`, "delete");
  return response;
});

//////
export const addNewSampleImages = createAsyncThunk("funcSamples/addNewSampleImages", async ({sampleId, formdata}) => {
  const response = fetchData(`/samples/images/add/${sampleId}`, "post", formdata);
  return response;
});

export const deleteSampleImage = createAsyncThunk("funcSamples/deleteSampleImage", async imageId => {
  const response = fetchData(`samples/images/remove/${imageId}`, "delete");
  return response;
});

export const addNewKitImages = createAsyncThunk("funcSamples/addNewKitImages", async ({kitId, formdata}) => {
  const response = fetchData(`/kits/images/add/${kitId}`, "post", formdata);
  return response;
});

export const deleteKitImage = createAsyncThunk("funcSamples/deleteKitImage", async imageId => {
  const response = fetchData(`kits/images/remove/${imageId}`, "delete");
  return response;
});

//*** Sample actions ***
export const restoreSample = createAsyncThunk("sample/restoreSample", async ({note, sampleIdList}) => {
  return fetchData(`/samples/restore`, "patch", sampleIdList, null, false, note ? {note} : {});
});
export const takeUnderReportSample = createAsyncThunk(
  "sample/takeUnderReportSample",
  async ({dateTo, note, managerId, sampleIdList}) => {
    return fetchData(`/samples/takeUnderReport`, "patch", sampleIdList, null, false, {dateTo, note, managerId});
  },
);
export const returnToBrandManagerSample = createAsyncThunk(
  "sample/returnToBrandManagerSample",
  async ({note, sampleIdList}) => {
    return fetchData(`/samples/returnToBrandManager`, "patch", sampleIdList, null, false, note ? {note} : {});
  },
);
export const transferToWarehouseSample = createAsyncThunk(
  "sample/transferToWarehouseSample",
  async ({note, sampleIdList}) => {
    return fetchData(`/samples/toWarehouse`, "patch", sampleIdList, null, false, note ? {note} : {});
  },
);
export const transferToWarehouseForWriteOffSample = createAsyncThunk(
  "sample/transferToWarehouseForWriteOffSample",
  async ({note, sampleIdList}) => {
    return fetchData(`/samples/toWarehouseForWriteOff`, "patch", sampleIdList, null, false, note ? {note} : {});
  },
);
export const writeOffSample = createAsyncThunk("sample/writeOffSample", async ({note, sampleIdList}) => {
  return fetchData(`/samples/writeOff`, "patch", sampleIdList, null, false, note ? {note} : {});
});
export const markForWriteOffSample = createAsyncThunk("sample/markForWriteOffSample", async ({note, sampleIdList}) => {
  return fetchData(`/samples/forWriteOff`, "patch", sampleIdList, null, false, note ? {note} : {});
});

//*** Kit actions ***
export const restoreKit = createAsyncThunk("sample/restoreKit", async ({note, kitIdList}) => {
  return fetchData(`/kits/restore`, "patch", kitIdList, null, false, note ? {note} : {});
});
export const takeUnderReportKit = createAsyncThunk(
  "sample/takeUnderReportKit",
  async ({dateTo, note, managerId, kitIdList}) => {
    return fetchData(`/kits/takeUnderReport`, "patch", kitIdList, null, false, {dateTo, note, managerId});
  },
);
export const returnToBrandManagerKit = createAsyncThunk("sample/returnToBrandManagerKit", async ({note, kitIdList}) => {
  return fetchData(`/kits/returnToBrandManager`, "patch", kitIdList, null, false, note ? {note} : {});
});
export const transferToWarehouseKit = createAsyncThunk("sample/transferToWarehouseKit", async ({note, kitIdList}) => {
  return fetchData(`/kits/toWarehouse`, "patch", kitIdList, null, false, note ? {note} : {});
});
export const transferToWarehouseForWriteOffKit = createAsyncThunk(
  "sample/transferToWarehouseForWriteOffKit",
  async ({note, kitIdList}) => {
    return fetchData(`/kits/toWarehouseForWriteOff`, "patch", kitIdList, null, false, note ? {note} : {});
  },
);
export const writeOffKit = createAsyncThunk("sample/writeOffKit", async ({note, kitIdList}) => {
  return fetchData(`/kits/writeOff`, "patch", kitIdList, null, false, note ? {note} : {});
});
export const markForWriteOffKit = createAsyncThunk("sample/markForWriteOffKit", async ({note, kitIdList}) => {
  return fetchData(`/kits/forWriteOff`, "patch", kitIdList, null, false, note ? {note} : {});
});

const userSlice = createSlice({
  name: "sample",
  initialState,
  reducers: {
    deselectSample: state => {
      state.currentSample = null;
    },
    cleanUpCurrent: state => {
      state.current = null;
    },
    setMyCurrentSampleObj: (state, {payload}) => {
      state.currentSample = payload;
    },
    cleanUpMySamples: state => {
      state.mySamples = [];
    },
    setLocationHistory: (state, {payload}) => {
      state.locationHistory = payload;
    },
    setScannedSamples: (state, {payload}) => {
      state.scannedSamples = payload;
      sessionStorage.setItem("scanned_samples", payload);
    },
    addSampleToSet: (state, {payload}) => {
      state.scannedSamples = [...state.scannedSamples, payload];
      sessionStorage.setItem("scanned_samples", JSON.stringify(state.scannedSamples));
    },
    setActionType: (state, {payload}) => {
      state.currentActionType = payload;
      sessionStorage.setItem("ActionType", payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getSample.fulfilled, (state, {payload}) => {
        state.currentSample = payload;
      })
      .addCase(fetchByQR.fulfilled, (state, {payload}) => {
        var type = Object.prototype.hasOwnProperty.call(payload, "sampleId") ? "sample" : "kit";
        state.current = {...payload, type};
      })
      .addCase(fetchByQR.rejected, (state, {error}) => {
        console.log("(fetchByQR.rejected payload :", error.message);
        getError("No such QR code in the database!");
        state.current = null;
      })
      .addCase(fetchMySamples.pending, state => {
        state.tableLoading = true;
      })
      .addCase(fetchMySamples.fulfilled, (state, {payload}) => {
        state.mySamples = payload.samples;
        state.tableLoading = false;
      })
      .addCase(fetchMySamples.rejected, state => {
        state.tableLoading = false;
      })
      .addCase(fetchKits.fulfilled, (state, {payload}) => {
        state.kits = payload;
      })
      .addCase(takeUnderReport.fulfilled, (state, {payload}) => {
        // state.current = null;

        getInfo(`Екземпляр ${payload.skuNumber} взято у п/з`);
      })
      .addCase(takeKitUnderReport.fulfilled, (state, {payload}) => {
        // state.current = null;
        getInfo(`Kit ${payload.skuNumber} взято у п/з`);
      })
      //// return sample
      .addCase(returnToStock.pending, state => {
        state.tableLoading = true;
      })
      .addCase(returnToStock.fulfilled, (state, {payload}) => {
        // state.currentSample = null;
        state.mySamples = state.mySamples.filter(samp => samp.sampleId !== payload.sampleId);
        state.tableLoading = false;
        getInfo(`Екземпляр ${payload.skuNumber} повернуто у сток`);
      })
      .addCase(returnToStock.rejected, state => {
        state.tableLoading = false;
      })
      ///return kit
      .addCase(returnKitToStock.pending, state => {
        state.tableLoading = true;
      })
      .addCase(returnKitToStock.fulfilled, (state, {payload}) => {
        state.currentSample = null;
        state.tableLoading = false;
        getInfo(`kitSKU №${payload.skuNumber} повернуто у сток`);
      })
      .addCase(returnKitToStock.rejected, state => {
        state.tableLoading = false;
      })

      ////////// images
      .addCase(addNewImage.fulfilled, (state, {payload}) => {
        state.current = {...state.current, images: payload};
        getInfo("Фото успішно завантажено");
      })
      .addCase(deleteImage.fulfilled, (state, {payload}) => {
        state.current = {...state.current, images: payload};
        getInfo("Фото успішно видалено");
      })

      //fulfilled matcher
      .addMatcher(
        action => action.type.startsWith("sample") && action.type.endsWith("/fulfilled"),
        state => handleFulfilled(state),
      )
      //pending matcher
      .addMatcher(
        action => action.type.startsWith("sample") && action.type.endsWith("/pending"),
        state => handlePending(state),
      )
      //rejected matcher
      .addMatcher(
        action => action.type.startsWith("sample") && action.type.endsWith("/rejected"),
        (state, {error}) => handleRejected(state, error),
      )

      .addDefaultCase(() => {});
  },
});

function handleFulfilled(state) {
  state.loading = false;
  state.error = false;
}

function handlePending(state) {
  state.loading = true;
  state.error = false;
}

function handleRejected(state, error) {
  state.loading = false;
  state.error = error;
}

const {actions, reducer} = userSlice;

export const {
  deselectSample,
  setMyCurrentSampleObj,
  cleanUpMySamples,
  cleanUpCurrent,
  setLocationHistory,
  addSampleToSet,
  setScannedSamples,
  setActionType,
} = actions;

export default reducer;

export const getCurrentQrCode = state => state.sample.current?.qrCodeUrl || null;
export const getCurrentEntity = state => state?.sample?.current;
// ? {
//     ...state.sample.current,
//     createdAt: "[" + moment(state.sample?.current?.createdAt).format("DD.MM.YY  HH:mm:ss") + "]",
//   }
// : null;
export const getMyCurrentSample = state => state.sample.currentSample;
// export const getCurrentKit = state => state.sample.currentKit;
export const getMySamples = state => state.sample.mySamples;
export const getTableLoading = state => state.sample.tableLoading;

export const getLocationHistory = state => state.sample.locationHistory;
export const getScannedSampleSet = state => state.sample.scannedSamples;
export const getCurrentActionType = state => state.sample.currentActionType;
export const selectSampleLoading = state => state.sample.loading;
export const selectKits = state => state.sample.kits;
