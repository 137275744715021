export const REPORT_MANAGER_SKU_ACTION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 75,
  },
  {
    header: "report M",
    accessorKey: "reportManager",
    size: 100,
  },
]);

export const REPORT_MANAGER_RM_SKU_ACTION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 75,
  },
  {
    header: "report RM",
    accessorKey: "reportManager",
    size: 100,
  },
]);

export const FOR_REPORT_MANAGER_SKU_ACTION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 75,
  },
  {
    header: "report for M",
    accessorKey: "reportManager",
    size: 100,
  },
]);

export const WAREHOUSE_SKU_ACTION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 75,
  },
  {
    header: "Warehouse",
    accessorKey: "warehouse",
    size: 100,
  },
]);

export const INVENTRORY_SKU_ACTION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 75,
  },
  {
    header: "Inventory date",
    accessorKey: "inventoryDate",
    size: 75,
  },
]);

export const WRITE_OFF_SKU_ACTION_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 25,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 75,
  },
  {
    header: "Write off date",
    accessorKey: "writeOffDate",
    size: 75,
  },
]);
