import {t} from "i18next";

import TextAreaInput from "../../../utilities/Inputs/TextAreaInput";
import TextInput from "../../../utilities/Inputs/TextInput";
import withActionsDependent from "../ActionsDependentHoc/ActionsDependent.hoc";
import {ACTION_TYPES} from "../constants";

import useSkuListBase from "./useSkuListBase";
import SkuListActionCase from "./common/SkuListActionCase";

const SkuListBase = ({actionType}) => {
  const {
    onTakeToReportBtnClick,
    onReturnFromReportBtnClick,
    onRestoreFromReportBtnClick,
    onDeleteBtnClick,
    onVerifyInventoryBtnClick,
    onWriteOffBtnClick,
    onTakeToReportRMBtnClick,
    onGiveToReportBtnClick,
    initialValues,
    skuActionTableData,
    loading,
    onMoveToWarehouseBtnClick,
  } = useSkuListBase({
    actionType,
  });

  switch (actionType) {
    case ACTION_TYPES.takeToReport:
      return (
        <SkuListActionCase
          title="SKU register in report:"
          leftBtnName="Take in report"
          onLeftBtnClick={onTakeToReportBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
        />
      );
    case ACTION_TYPES.returnFromReport:
      return (
        <SkuListActionCase
          title="Returned from report SKU list:"
          leftBtnName="Return from report"
          onLeftBtnClick={onReturnFromReportBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
        />
      );
    case ACTION_TYPES.giveToReport:
      return (
        <SkuListActionCase
          title="Given to report SKU list:"
          leftBtnName="Give to report"
          onLeftBtnClick={onGiveToReportBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
          initialValues={initialValues}
        >
          <TextInput
            label={t("Report for Manager")}
            name={"brandManager"}
            inputclassname="skuInput"
            disabled
            width="100%"
            style={{width: "100%"}}
          />
          <TextAreaInput name={"note"} placeholder={t("Примітка")} height={"55px"} readonly required />
        </SkuListActionCase>
      );
    case ACTION_TYPES.restoreFromReport:
      return (
        <SkuListActionCase
          title="SKU registers to restore from report:"
          leftBtnName="Restore from report"
          onLeftBtnClick={onRestoreFromReportBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
        />
      );
    case ACTION_TYPES.toWarehouse:
      return (
        <SkuListActionCase
          title="SKU registers to move to warehouse:"
          leftBtnName="Move"
          onLeftBtnClick={onMoveToWarehouseBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
          initialValues={initialValues}
        >
          <TextAreaInput name={"note"} placeholder={t("Примітка")} height={"55px"} readonly required />
        </SkuListActionCase>
      );
    case ACTION_TYPES.inventory:
      return (
        <SkuListActionCase
          title="SKU Inventory Register:"
          leftBtnName="Verify"
          onLeftBtnClick={onVerifyInventoryBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
        />
      );
    case ACTION_TYPES.forWriteOffWarehouse:
      return (
        <SkuListActionCase
          title="List of SKUs for write-off (warehouse):"
          leftBtnName="Write off"
          onLeftBtnClick={onWriteOffBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
        />
      );
    case ACTION_TYPES.takeToReportRM:
      return (
        <SkuListActionCase
          title="SKU register in report RM:"
          leftBtnName="Take in report RM"
          onLeftBtnClick={onTakeToReportRMBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
        />
      );
    case ACTION_TYPES.forWriteOff:
      return (
        <SkuListActionCase
          title="List of SKUs for write-off:"
          leftBtnName="Write off"
          onLeftBtnClick={onWriteOffBtnClick}
          onRightBtnClick={onDeleteBtnClick}
          loading={loading}
          skuActionTableData={skuActionTableData}
          actionType={actionType}
        />
      );

    default:
      return <div className="fc aic jcc w100 h100">{t("Wrong URL")}</div>;
  }
};

export default withActionsDependent(SkuListBase);
