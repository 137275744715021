import React, {useState} from "react";
import {SwipeableDrawer} from "@mui/material";
import {t} from "i18next";

import FunctionButton from "../../../utilities/Buttons/FunctionButton";
import TextInput from "../../../utilities/Inputs/TextInput";

const CommonKitSampleActionPart = ({isKit = false}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <div className="fcCol aic gap0_7 w100">
      <TextInput label={t("Status ")} name={"status"} width={"100%"} readOnly style={{width: "15rem"}} />
      <TextInput
        label={isKit ? t("kitSKU №:") : t("SKU №:")}
        name={isKit ? "kitSkuNumber" : "skuNumber"}
        width={"100%"}
        readOnly
      />
      <TextInput label={t("nomination")} name={"title"} width={"100%"} readOnly />
      {!isKit && (
        <FunctionButton
          name="Detailed information about the sample"
          autoWidth
          style={{backgroundColor: "transparent"}}
          className="mt0_5"
          onClick={() => {
            setDrawerOpen(true);
          }}
        />
      )}

      <SwipeableDrawer
        container={window.document.body}
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
        onOpen={() => {}}
        // swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <div className="fcCol gap textFormat" style={{width: "85%", alignSelf: "center", padding: "2rem"}}>
          <TextInput label={t("Collection")} name={"collection"} width={"100%"} readOnly />
          <TextInput label={t("Color")} name={"color"} width={"100%"} readOnly />
          <TextInput label={t("Texture")} name={"texture"} width={"100%"} readOnly />
          <TextInput label={t("Material")} name={"material"} width={"100%"} readOnly />
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default CommonKitSampleActionPart;
