import {withFormik} from "formik";
import {t} from "i18next";

import DateInput from "../../../utilities/Inputs/DateInput";
import {Select} from "../../../utilities/Inputs/SelectField";
import TextAreaInput from "../../../utilities/Inputs/TextAreaInput";
import TextInput from "../../../utilities/Inputs/TextInput";
import withActionsDependent from "../ActionsDependentHoc/ActionsDependent.hoc";
import {ACTION_TYPES} from "../constants";
import ManagersSelect from "../SampleAction/common/ManagersSelect";
import SkuActionTable from "../SkuActionTable/SkuActionTable";

import KitActionCase from "./common/KitActionCase";
import useKitActionBase from "./useKitActionBase";

const KitActionBase = ({actionType}) => {
  const {
    onTakeToReportBtnClick,
    onVerifyInventoryBtnClick,
    onWriteOffToWarehouseBtnClick,
    loading,
    images,
    onTakeToReportRMBtnClick,
    onGoBackBtnClick,
    onReturnFromReportBtnClick,
    onGiveToReportBtnClick,
    onRestoreFromReportBtnClick,
    restoreFromReportTableColumns,
    restoreFromReportTableData,
    onMoveToWarehouseBtnClick,
    warehouseOptions,
    managerOptions,
    // onWriteOffBtnClick
  } = useKitActionBase({
    actionType,
  });

  switch (actionType) {
    case ACTION_TYPES.takeToReport:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onTakeToReportBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Take in report"
          title="Take 'Kit' under report"
        >
          <DateInput label={t("Report BEFORE:")} name="dateTo" isRowLayout />
          <TextInput label={t("Manager ")} name={"manager"} width={"100%"} readOnly inputclassname="skuInput" />
          <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} required />
        </KitActionCase>
      );
    case ACTION_TYPES.returnFromReport:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onReturnFromReportBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Return from report"
          title="Return 'Kit' from report"
        >
          <DateInput label={t("Report BEFORE:")} name="dateTo" isRowLayout readOnly={true} />
          <TextInput label={t("Brand Manager")} name={"brandManager"} width={"100%"} readOnly={true} />
          <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} readOnly={true} />
        </KitActionCase>
      );
    case ACTION_TYPES.giveToReport:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onGiveToReportBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Give to report"
          title="Give 'Kit' to report"
        >
          <DateInput label={t("Report BEFORE:")} name="dateTo" isRowLayout />
          <ManagersSelect />
          <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} />
        </KitActionCase>
      );
    case ACTION_TYPES.restoreFromReport:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onRestoreFromReportBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Restore from report"
          title="Restore 'Kit' from report"
          nearImgPart={<DateInput label={t("Report BEFORE:")} name="dateTo" readOnly />}
        >
          <TextInput label={t("Report Manager")} name={"reportManager"} width={"100%"} readOnly />
          <SkuActionTable
            data={restoreFromReportTableData}
            actionType={actionType}
            loading={loading}
            columns={restoreFromReportTableColumns}
            isKit
          />
        </KitActionCase>
      );
    case ACTION_TYPES.toWarehouse:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onMoveToWarehouseBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Move"
          title="Move 'Kit' to warehouse"
          nearImgPart={<DateInput label={t("Report BEFORE:")} name="dateTo" readOnly />}
        >
          <Select label={t("Warehouse")} name={"warehouse"} width={"100%"} options={warehouseOptions} disabled />
          <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} />
        </KitActionCase>
      );
    case ACTION_TYPES.inventory:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onVerifyInventoryBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Verify"
          title="Verify 'Kit' from report"
          nearImgPart={<DateInput label={t("Inventory Date:")} name="inventoryDate" readOnly />}
        >
          <TextInput label={t("Report Manager")} name={"reportManager"} width={"100%"} readOnly />
          <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} />
        </KitActionCase>
      );
    case ACTION_TYPES.forWriteOffWarehouse:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onWriteOffToWarehouseBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Write off"
          title="Write off the 'Kit' (warehouse)"
          nearImgPart={<DateInput label={t("Write off date:")} name="writeOffDate" readOnly />}
        >
          <Select label={t("Warehouse")} name={"warehouse"} width={"100%"} options={warehouseOptions} disabled />
          <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} />
        </KitActionCase>
      );
    case ACTION_TYPES.takeToReportRM:
      return (
        <KitActionCase
          images={images}
          onLeftBtnClick={onTakeToReportRMBtnClick}
          onRightBtnClick={onGoBackBtnClick}
          leftBtnName="Take in report RM"
          title="Take 'Kit' under report (report RM)"
        >
          <DateInput label={t("Report BEFORE:")} name="dateTo" isRowLayout readOnly />
          <TextInput label={t("Manager ")} name={"manager"} width={"100%"} readOnly inputclassname="skuInput" />
          <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} />
        </KitActionCase>
      );
    // case ACTION_TYPES.forWriteOff:
    //   return (
    //     <KitActionCase
    //       images={images}
    //       onLeftBtnClick={onWriteOffBtnClick}
    //       onRightBtnClick={onGoBackBtnClick}
    //       leftBtnName="Write off"
    //       title="Write off the 'Kit'"
    //     >
    //       <DateInput label={t("Write off date:")} name="writeOffDate" isRowLayout />
    //       <TextInput
    //         label={t("Brand Manager")}
    //         name={"brandManager"}
    //         width={"100%"}
    //         readOnly
    //         inputclassname="skuInput"
    //       />
    //       <TextAreaInput name={"note"} placeholder={t("Note")} height={"55px"} />
    //     </KitActionCase>
    //   );

    default:
      return <div className="fc aic jcc w100 h100">{t("Wrong URL")}</div>;
  }
};

export default withActionsDependent(withFormik({enableReinitialize: true})(KitActionBase));
