import {t} from "i18next";
import FunctionButton from "../../../../utilities/Buttons/FunctionButton";
import SkuActionTable from "../../SkuActionTable/SkuActionTable";
import {Form, Formik} from "formik";
import ImageButton from "../../../../utilities/Buttons/ImageBtn";
import * as Yup from "yup";
import DeleteIcon from "../../../../images/icons/Trash.png";

const SkuListActionCase = ({
  title,
  actionType,
  loading = false,
  skuActionTableData,
  leftBtnName,
  onLeftBtnClick,
  onRightBtnClick,
  children,
  initialValues = {},
}) => {
  return (
    <div className="h100">
      <h1 className="centered mb1_5x">{t(title)}</h1>
      <div className="form_wrapper gap1_5" style={{height: "calc(100% - 2em)"}}>
        <SkuActionTable data={skuActionTableData} actionType={actionType} loading={loading} />
        {children && (
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({})}
            validateOnChange={false}
            validateOnBlur={false}
            enableReinitialize
          >
            <Form className="fcCol gap5">{children}</Form>
          </Formik>
        )}
        <div className="row jcsa">
          <FunctionButton name={leftBtnName} className="bigBtn" onClick={onLeftBtnClick} style={{height: "100%"}} />
          <ImageButton src={DeleteIcon} onClick={onRightBtnClick} width={3} height={3} />
        </div>
      </div>
    </div>
  );
};

export default SkuListActionCase;
