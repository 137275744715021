export const ROUTES = Object.freeze({
  SAMPLES_INFO: "/ba3a/samples/actions/sample",
  KITS_INFO: "/ba3a/samples/actions/kit",
  LOGIN: "/login",
  SCANER: "/ba3a/samples/qrread",
  MANAGER_ACTIONS: "/ba3a/samples/userActionType",
  RETURN_FORM: "/ba3a/samples/return-sample",
  SKU_LIST: "/ba3a/samples/sku-list",
  SAMPLES_SET_LIST: "/ba3a/samples/actions/skuList",
  SAMPLE_IMAGE: "/ba3a/samples/add-samples-photo",
  SAMPLE_IMAGE_SCANER: "/ba3a/samples/samples-image",
});
