import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useFormikContext} from "formik";
import {t} from "i18next";

import {
  cleanUpCurrent,
  fetchByQR,
  getCurrentActionType,
  getCurrentEntity,
  restoreKit,
  returnToBrandManagerKit,
  selectKits,
  selectSampleLoading,
  takeUnderReportKit,
  transferToWarehouseForWriteOffKit,
  transferToWarehouseKit,
} from "../../../slices/SampleSlice";
import {getCurrentUser, selectAllUsers} from "../../../slices/UserSlice";
import {dateFormat} from "../../../utilities/dateFormat";
import {ROUTES} from "../../../utilities/routes";
import {swalCustom} from "../../../utilities/swalCustom";
import {toaster} from "../../../utilities/toaster";
import useColumns from "../../../utilities/useColumns";
import {NO_NOTE_ACTIONS} from "../constants";
import {RESTORE_SAMPLE_FROM_REPORT_TABLE_COLUMNS} from "../SampleAction/common/columns";

const useKitActionBase = ({actionType}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const {dateTo, note, reportForManagerId} = values;

  const loading = useSelector(selectSampleLoading);
  const currentKit = useSelector(getCurrentEntity);
  const currentUser = useSelector(getCurrentUser);
  const currentUserKits = useSelector(selectKits);
  const allUsers = useSelector(selectAllUsers),
    currentActionType = useSelector(getCurrentActionType);

  const images = currentKit?.images?.map(image => image.imageUrl) ?? [];
  const warehouseOptions = []; //!No functional
  const managerOptions =
    allUsers?.map(({userId, firstName, lastName}) => ({value: userId, title: `${firstName} ${lastName}`})) ?? [];

  const location = useLocation();
  const scannedQr = location.state?.qrUrl;

  useEffect(() => {
    if (scannedQr && !currentKit) {
      dispatch(fetchByQR(scannedQr));
    }
  }, [scannedQr]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem(
        "actionInputData",
        JSON.stringify({
          note: values.note,
          dateTo: values.dateTo,
          reportForManagerId: values.reportForManagerId,
        }),
      );
    };
  }, []);

  useEffect(() => {
    if (currentKit && currentKit?.type === "kit")
      try {
        const {
          note: savedNote,
          dateTo: savedDateTo,
          reportForManagerId: savedReportForManagerId,
        } = JSON.parse(sessionStorage.getItem("actionInputData")) ?? {};

        setValues({
          status: currentKit?.status?.name ?? "",
          kitSkuNumber: currentKit?.skuNumber ?? "",
          title: currentKit?.name ?? "",
          dateTo: currentKit?.dateTo ?? savedDateTo ?? "",
          note: (NO_NOTE_ACTIONS.includes(actionType) ? currentKit?.note : savedNote) ?? "",
          reportManager: currentKit?.manager
            ? `${currentKit?.manager?.firstName} ${currentKit?.manager?.lastName}`
            : "",
          brandManager: `${currentKit?.brandManager?.firstName} ${currentKit?.brandManager?.lastName}` ?? "",
          manager: `${currentUser?.firstName} ${currentUser?.lastName}` ?? "",
          inventoryDate: new Date().toDateString(),
          writeOffDate: new Date().toDateString(),
          reportForManagerId: savedReportForManagerId ?? "",
        });
      } catch (error) {
        console.error(error);
      }
  }, [currentKit?.kitId, currentUser?.userId, currentActionType]);

  useEffect(() => {
    if (currentKit && currentKit?.samplesInBox < currentKit?.samplesCount) {
      swalCustom.info({
        title: t("samplesInBox", {samplesInBox: currentKit?.samplesInBox, samplesCount: currentKit?.samplesCount}),
        showCancelButton: false,
      });
    }
  }, [currentKit?.samplesCount, currentKit?.samplesInBox]);

  const restoreFromReportTableColumns = useColumns(RESTORE_SAMPLE_FROM_REPORT_TABLE_COLUMNS);
  const restoreFromReportTableData =
    currentUserKits?.map(({kitId, skuNumber, dateTo}, index) => ({
      number: ++index,
      id: kitId,
      skuNumber,
      dateTo: dateFormat(dateTo) ?? "-",
    })) ?? [];

  function cleanUpAndReturn() {
    dispatch(cleanUpCurrent());
    navigate(ROUTES.SCANER);
  }

  function onTakeToReportBtnClick() {
    if (!currentKit || !dateTo || !note) return;

    dispatch(takeUnderReportKit({dateTo, note, kitIdList: [currentKit.kitId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Kit was taken under report");
        cleanUpAndReturn();
      }
    });
  }

  function onReturnFromReportBtnClick() {
    if (!currentKit) return;

    dispatch(returnToBrandManagerKit({note, kitIdList: [currentKit.kitId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Kit returned to Brand Manager");
        cleanUpAndReturn();
      }
    });
  }

  function onGiveToReportBtnClick() {
    if (!currentKit || !dateTo || !note || !reportForManagerId) return;

    dispatch(takeUnderReportKit({dateTo, note, managerId: reportForManagerId, kitIdList: [currentKit.kitId]})).then(
      resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          toaster.success("Kit was taken under report");
          cleanUpAndReturn();
        }
      },
    );
  }

  function onRestoreFromReportBtnClick() {
    if (!currentKit) return;

    dispatch(restoreKit({note, kitIdList: [currentKit.kitId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Kit restored");
        cleanUpAndReturn();
      }
    });
  }
  function onMoveToWarehouseBtnClick() {
    if (!currentKit) return;

    dispatch(transferToWarehouseKit({note, kitIdList: [currentKit.kitId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Kit transfered to Warehouse");
        cleanUpAndReturn();
      }
    });
  }

  function onWriteOffToWarehouseBtnClick() {
    console.log("currentKit onWriteOffToWarehouseBtnClick:", currentKit);
    if (!currentKit) return;

    dispatch(transferToWarehouseForWriteOffKit({note, kitIdList: [currentKit.kitId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Kit transfered to Warehouse for write off");
        cleanUpAndReturn();
      }
    });
  }

  function onVerifyInventoryBtnClick() {
    if (!currentKit) return;

    dispatch(restoreKit({note, kitIdList: [currentKit.kitId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Kit restored");
        cleanUpAndReturn();
      }
    });
  }

  // function onWriteOffBtnClick() {
  //   if (!currentSample) return;

  //   dispatch(writeOffSample([currentSample.sampleId])).then(resp => {
  //     if (resp.meta.requestStatus === "fulfilled") {
  //       toaster.success("Sample was written off");
  //       cleanUpAndReturn();
  //     }
  //   });
  // }

  function onTakeToReportRMBtnClick() {
    if (!currentKit || !dateTo || !note) return;

    dispatch(takeUnderReportKit({dateTo, note, kitIdList: [currentKit.kitId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Kit was taken under report");
        cleanUpAndReturn();
      }
    });
  }

  function onGoBackBtnClick() {
    cleanUpAndReturn();
  }

  return {
    images,
    onTakeToReportBtnClick,
    onReturnFromReportBtnClick,
    onRestoreFromReportBtnClick,
    onMoveToWarehouseBtnClick,
    onVerifyInventoryBtnClick,
    // onWriteOffBtnClick,
    onTakeToReportRMBtnClick,
    loading,
    onGiveToReportBtnClick,
    restoreFromReportTableColumns,
    restoreFromReportTableData,
    warehouseOptions,
    managerOptions,
    onGoBackBtnClick,
    onWriteOffToWarehouseBtnClick,
  };
};

export default useKitActionBase;
