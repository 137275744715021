import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {styled} from "@mui/material/styles";

import {setActionType} from "../../slices/SampleSlice";
import {getCurrentUser, logout} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import {ROUTES} from "../../utilities/routes";
import {ACTION_TYPES} from "../ActionDependentComponents/constants";

import {ROLE_TYPES} from "./roleTypes";

const FormControlLabelBordered = styled(FormControlLabel)(({color}) => ({
  border: `5px solid ${color}`,
  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.3)",
  padding: "8px",
  marginBottom: "20px",
  ".MuiFormControlLabel-label": {
    marginLeft: "15px", // Здесь вы можете указать нужное расстояние
  },
}));

const UserActions = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedAction, setSelectedAction] = useState(null);

  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    dispatch(setActionType(null));
  }, []);

  useEffect(() => {
    sessionStorage.removeItem("actionInputData");
  }, []);

  const handleChange = event => {
    setSelectedAction(event.target.value);
  };
  const onBackBtnClick = () => {
    dispatch(logout());
  };
  const navidateBtnClick = () => {
    dispatch(setActionType(selectedAction));
    navigate(ROUTES.SCANER);
  };

  const optionsRender = useCallback(() => {
    switch (currentUser?.role?.roleId) {
      case ROLE_TYPES.MANAGER:
        return (
          <>
            <FormControlLabelBordered
              value={ACTION_TYPES.takeToReport}
              control={<Radio />}
              label={t("Take in report")}
              color="rgba(163, 185, 217, 1)"
            />
            <FormControlLabelBordered
              value={ACTION_TYPES.returnFromReport}
              control={<Radio />}
              label={t("Return from report")}
              color="rgba(151, 71, 255, 1)"
            />
            <FormControlLabelBordered
              value={ACTION_TYPES.giveToReport}
              control={<Radio />}
              label={t("Transfer to the report")}
              color="rgba(70, 244, 255, 1)"
            />
          </>
        );
      case ROLE_TYPES.BRAND_MANAGER:
        return (
          <>
            <FormControlLabelBordered
              value={ACTION_TYPES.takeToReport}
              control={<Radio />}
              label={t("Take in report")}
              color="rgba(163, 185, 217, 1)"
            />
            <FormControlLabelBordered
              value={ACTION_TYPES.returnFromReport}
              control={<Radio />}
              label={t("Return from report")}
              color="rgba(151, 71, 255, 1)"
            />
            <FormControlLabelBordered
              value={ACTION_TYPES.giveToReport}
              control={<Radio />}
              label={t("Transfer to the report")}
              color="rgba(70, 244, 255, 1)"
              style={{marginBottom: "40px"}}
            />

            <FormControlLabelBordered
              value={ACTION_TYPES.restoreFromReport}
              control={<Radio />}
              label={t("Restore from the report")}
              color="rgba(79, 149, 255, 1)"
            />
            <FormControlLabelBordered
              value={ACTION_TYPES.toWarehouse}
              control={<Radio />}
              label={t("Move to warehouse")}
              color="rgba(48, 157, 9, 1)"
            />
            <FormControlLabelBordered
              value={ACTION_TYPES.inventory}
              control={<Radio />}
              label={t("Inventory")}
              color="rgba(224, 201, 2, 1)"
            />
            <FormControlLabelBordered
              value={ACTION_TYPES.forWriteOffWarehouse}
              control={<Radio />}
              label={t("Select for write-off (warehouse)")}
              color="rgba(255, 7, 7, 1)"
            />
          </>
        );
      case ROLE_TYPES.WAREHOUSE_MANGER:
        return (
          <FormControlLabelBordered
            value={ACTION_TYPES.takeToReport}
            control={<Radio />}
            label={t("Take in report")}
            color="rgba(163, 185, 217, 1)"
          />
        );
      case ROLE_TYPES.CONTROLLER:
        return (
          <FormControlLabelBordered
            value={ACTION_TYPES.forWriteOffWarehouse}
            control={<Radio />}
            label={t("Samples for writing off")}
            color="rgba(163, 185, 217, 1)"
          />
        );
      default:
        return <div>No actions</div>;
    }
  }, [currentUser]);

  const options = optionsRender();

  return (
    <FormControl className="fc fdc aic w100 h100 jcsb nowrap">
      <div>{t("Select Action")}:</div>
  
      <RadioGroup name="controlled-radio-buttons-group" value={selectedAction} style={{flexWrap: "nowrap"}} className={"actionsRadioGroup"} onChange={handleChange} >
        {options}
      </RadioGroup>

      <div className="fc nowrap" style={{gap: "30px"}}>
        <FunctionButton name="Further" onClick={navidateBtnClick} disabled={!selectedAction} className="bigBtn" />
        <FunctionButton name="Exit" onClick={onBackBtnClick} className="bigBtn" />
      </div>
    </FormControl>
  );
};

export default UserActions;
