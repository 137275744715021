import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {minHeight} from "@mui/system";

import {fetchKits, fetchMySamples} from "../../../slices/SampleSlice";
import {getCurrentUser} from "../../../slices/UserSlice";
import useColumns from "../../../utilities/useColumns";
import ReactTable from "../../containers/ReactTable";
import {ACTION_TYPES} from "../constants";

import {
  FOR_REPORT_MANAGER_SKU_ACTION_TABLE_COLUMNS,
  INVENTRORY_SKU_ACTION_TABLE_COLUMNS,
  REPORT_MANAGER_RM_SKU_ACTION_TABLE_COLUMNS,
  REPORT_MANAGER_SKU_ACTION_TABLE_COLUMNS,
  WAREHOUSE_SKU_ACTION_TABLE_COLUMNS,
  WRITE_OFF_SKU_ACTION_TABLE_COLUMNS,
} from "./columns";

const SkuActionTable = ({actionType, data = [], loading = false, columns, height, selectable = false, isKit}) => {
  let columnsRow;
  switch (actionType) {
    case ACTION_TYPES.takeToReport:
    case ACTION_TYPES.returnFromReport:
    case ACTION_TYPES.restoreFromReport:
      columnsRow = REPORT_MANAGER_SKU_ACTION_TABLE_COLUMNS;
      break;
    // case ACTION_TYPES.giveToReport:
    //   columnsRow = FOR_REPORT_MANAGER_SKU_ACTION_TABLE_COLUMNS;
    //   break;
    // case ACTION_TYPES.toWarehouse:
    // case ACTION_TYPES.forWriteOffWarehouse:
    //   columnsRow = WAREHOUSE_SKU_ACTION_TABLE_COLUMNS;
    //   break;
    // case ACTION_TYPES.inventory:
    //   columnsRow = INVENTRORY_SKU_ACTION_TABLE_COLUMNS;
    //   break;
    // case ACTION_TYPES.takeToReportRM:
    //   columnsRow = REPORT_MANAGER_RM_SKU_ACTION_TABLE_COLUMNS;
    //   break;
    // case ACTION_TYPES.forWriteOff:
    //   columnsRow = WRITE_OFF_SKU_ACTION_TABLE_COLUMNS;
    //   break;
  }

  const columnsDefault = useColumns(columnsRow);

  const currentUser = useSelector(getCurrentUser);
  const currentUserId = currentUser?.userId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUserId) {
      isKit
        ? dispatch(fetchKits({brandManagerId: currentUserId, statusId: 3}))
        : dispatch(fetchMySamples({brandManagerId: currentUserId, statusId: 3}));
    }
  }, [currentUserId]);

  return (
    <ReactTable
      defaultData={data}
      columns={columns || columnsDefault}
      selectable={selectable}
      current={null}
      // onSelect={() => {}}
      loading={loading}
      tableClassName="fGrow"
      className="fGrow"
      style={{height: height, minHeight: "100px"}}
    />
  );
};

export default SkuActionTable;
