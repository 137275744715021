import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {debounce} from "lodash";
import Swal from "sweetalert2";

import {
  fetchByQR,
  getCurrentActionType,
  getCurrentEntity,
  getScannedSampleSet,
  setScannedSamples,
} from "../../slices/SampleSlice";
import {getCurrentUser} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import {ROUTES} from "../../utilities/routes";
import {getError} from "../../utilities/toasts";
import {ACTION_TYPES} from "../ActionDependentComponents/constants";
import {ROLE_TYPES} from "../actions/roleTypes";

import NewQrScaner from "./NewQrScaner";

const QRReaderContainer = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [qrMessage, setQrMessage] = useState("");

  useEffect(() => {
    if (qrMessage) {
      sessionStorage.setItem("scannedQr", qrMessage);
    }
  }, [qrMessage]);

  const [scanerStop, setScanerStop] = useState(false);
  const samplesSet = useSelector(getScannedSampleSet);

  const navigate = useNavigate();

  const currentUser = useSelector(getCurrentUser),
    current = useSelector(getCurrentEntity),
    currentActionType = useSelector(getCurrentActionType);

  const fetchInfo = () => dispatch(fetchByQR(qrMessage));

  const debouncedFetch = debounce(fetchInfo, 100);

  //*1
  // const checkIsInSet = () => {
  //   samplesSet?.forEach(currentValue => {
  //     if (
  //       current?.kitId === currentValue?.kitId ||
  //       currentValue?.sampleId === current.sampleId ||
  //       current?.kit?.kitId === currentValue?.kitId
  //     ) {
  //       setTimeout(() => {
  //         setQrMessage("");
  //       }, 4000);
  //       throw new Error(`Already in set`);
  //     }
  //   });
  // };

  const checkSampleAccess = () => {
    switch (currentUser.role.roleId) {
      case ROLE_TYPES.MANAGER:
        if (currentActionType === ACTION_TYPES.takeToReport || currentActionType === ACTION_TYPES.takeToReportRM) {
          if (current.statusId === 2) {
            throw new Error(`The Sample already under storage manager report `);
          }
          if (current.statusId === 3) {
            if (current.managerId === currentUser.userId) {
              throw new Error(`The Sample already under yours report`);
            }
          }
          if (current.statusId === 4) {
            throw new Error(`The Sample with status 'списаний' Cannot be taken under report`);
          }
          if (current.isPending) {
            if (current.pendingAction.typeId === 3) {
              throw new Error(`The Sample is waiting 'на списання (склад)' Cannot be taken under report`);
            } else if (current.pendingAction.typeId === 4) {
              throw new Error(`The Sample is waiting 'на списання' Cannot be taken under report`);
            }
          }
        }
        if (currentActionType === ACTION_TYPES.returnFromReport) {
          if (current.isPending) {
            throw new Error(`The Sample is waiting for action`);
          }
          if (current.statusId !== 3) {
            throw new Error(`The Sample is not in report`);
          }
          if (current.statusId === 3 && current.managerId !== currentUser.userId) {
            throw new Error(`The Sample is not in your report`);
          }
        }
        break;
      case ROLE_TYPES.BRAND_MANAGER:
        if (currentActionType === ACTION_TYPES.takeToReport || currentActionType === ACTION_TYPES.takeToReportRM) {
          if (current.statusId === 2) {
            throw new Error(`The Sample already under storage manager report `);
          }
          if (current.statusId === 3) {
            if (current.managerId === currentUser.userId) {
              throw new Error(`The Sample already under yours report`);
            }
          }
          if (current.statusId === 4) {
            throw new Error(`The Sample with status 'списаний' Cannot be tacken under report`);
          }
          if (current.isPending) {
            if (current.pendingAction.typeId === 3) {
              throw new Error(`The Sample is waiting 'на списання (склад)' Cannot be taken under report`);
            } else if (current.pendingAction.typeId === 4) {
              throw new Error(`The Sample is waiting 'на списання' Cannot be taken under report`);
            }
          }
        }
        if (currentActionType === ACTION_TYPES.returnFromReport) {
          if (current.isPending) {
            throw new Error(`The Sample  is waiting for action`);
          }
          if (current.statusId !== 3) {
            throw new Error(`The Sample  is not in report`);
          }
          if (current.statusId === 3 && current.managerId !== currentUser.userId) {
            throw new Error(`The Sample is not in your report`);
          }
        }
        if (currentActionType === ACTION_TYPES.restoreFromReport) {
          if (Number(current.statusId) === 4) {
            throw new Error(`The sample with status 'списаний' Cannot be restored`);
          }
          if (Number(current.brandManagerId) !== Number(currentUser.userId)) {
            throw new Error(`The sample owned by another Brand Manager cannot be restored by You`);
          }
        }
        if (currentActionType === ACTION_TYPES.toWarehouse) {
          if (current.isPending) {
            throw new Error(`The Sample is waiting for action`);
          }
          if (current.statusId !== 1) {
            throw new Error(`The Sample wich is not in status 'у власника' Cannot be transfered to warehouse`);
          }
        }
        if (currentActionType === ACTION_TYPES.forWriteOffWarehouse) {
          if (current.isPending) {
            throw new Error(`The Sample is waiting for action`);
          }
          if (current.statusId !== 1) {
            throw new Error(`The Sample wich is not in status 'у власника' Cannot be transfered to warehouse`);
          }
        }
        if (currentActionType === ACTION_TYPES.inventory) {
          if (current.managerId !== currentUser.userId) {
            throw new Error(`The sample owned by another Brand Manager`);
          }
          if (current.statusId === 2) {
            throw new Error(`The Sample is under storage manager report`);
          }
          if (current.statusId === 1 && current.pendingAction.typeId === 4) {
            throw new Error(`The Sample is waiting for 'списання'`);
          }
        }
        if (currentActionType === ACTION_TYPES.forWriteOff) {
          if (current.brandManagerId !== currentUser.userId)
            throw new Error(`The sample owned by another Brand Manager`);
          if (current.statusId === 1 || current.statusId === 2) {
            break;
          } else {
            throw new Error(`The Sample must be in status 'у власника' or 'в п/з МС'`);
          }
        }
        break;
      case ROLE_TYPES.WAREHOUSE_MANGER:
        if (currentActionType === ACTION_TYPES.takeToReportRM) {
          if (current.statusId === 1 && (current.pendingAction.typeId === 2 || current.pendingAction.typeId === 3)) {
            break;
          } else {
            throw new Error(`The Sample must be on Storage or written off(storage)`);
          }
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (qrMessage && qrMessage !== "") {
      debouncedFetch();
    }
  }, [qrMessage]);

  useEffect(() => {
    if (qrMessage && current) {
      try {
        // checkIsInSet();   //*2
        checkSampleAccess();
        setScanerStop(true);
        if (current?.sampleId) navigate(`${ROUTES.SAMPLES_INFO}/${currentActionType}`, {state: {qrUrl: qrMessage}});
        else if (current?.kitId) navigate(`${ROUTES.KITS_INFO}/${currentActionType}`, {state: {qrUrl: qrMessage}});
      } catch (error) {
        getError(error.message);
        setTimeout(() => {
          setQrMessage("");
        }, 4000);
      }
    }
  }, [current]);

  const onExit = () => {
    if (samplesSet?.length > 0) {
      Swal.fire({
        title: t(`Exit`) + "?",
        text: t(`All samples will be removed from the registry!`),
        icon: "question",
        confirmButtonText: t("Yes"),
        cancelButtonText: t("No"),
        showCancelButton: true,
        cancelButtonColor: "#A9A9A9",
      }).then(result => {
        if (result.isConfirmed) {
          dispatch(setScannedSamples(null));
          navigate(ROUTES.MANAGER_ACTIONS);
        }
      });
    } else navigate(ROUTES.MANAGER_ACTIONS);
  };

  //*3
  // const onSamplesSetClick = () => {
  //   navigate(`${ROUTES.SAMPLES_SET_LIST}/${currentActionType}`);
  // };

  return (
    <div className="fc aic jcc h100  w100 qrContainer" style={{padding: "0 10px"}}>
      <div className="w100" style={{textAlign: "center", padding: "15px"}}>
        {t("Scan the sample")}
      </div>
      {/* //*4 
      <div className="sample_circule_container" onClick={onSamplesSetClick}> 
        {samplesSet?.length > 0 &&
          samplesSet.map((entity, i) => (
            <Avatar sx={{width: 50, height: 50}} key={i} src={`${apiUrl}/${entity?.image?.imageUrl}`}>
              {entity?.sampleId}
            </Avatar>
          ))}
      </div> */}
      <div className="centeredFull   aic jcc" style={{flex: "auto"}}>
        <div className="scaner_container">
          <NewQrScaner resultMessage={qrMessage} setResultMessage={setQrMessage} stop={scanerStop} />
        </div>

        <div className="fc g1 aic jcc" style={{position: "absolute", bottom: "1em", right: "5px"}}>
          {/* <ImageButton src={ratateCameraImg} onClick={() => setFacingMode(facingMode => !facingMode)} size={2} /> */}
          <FunctionButton name="Exit" onClick={onExit} className="centered bigBtn" />
        </div>
      </div>
    </div>
  );
};

export default QRReaderContainer;
