const useSkuListBase = ({actionType}) => {
  const initialValues = {};
  const loading = false;

  const skuActionTableData = [];

  function onTakeToReportBtnClick() {}
  function onReturnFromReportBtnClick() {}
  function onRestoreFromReportBtnClick() {}
  function onMoveToWarehouseBtnClick() {}
  function onVerifyInventoryBtnClick() {}
  function onWriteOffBtnClick() {}
  function onTakeToReportRMBtnClick() {}
  function onGiveToReportBtnClick() {}

  function onDeleteBtnClick() {}

  return {
    onTakeToReportBtnClick,
    onDeleteBtnClick,
    onReturnFromReportBtnClick,
    onRestoreFromReportBtnClick,
    onMoveToWarehouseBtnClick,
    onVerifyInventoryBtnClick,
    onWriteOffBtnClick,
    onTakeToReportRMBtnClick,
    initialValues,
    skuActionTableData,
    loading,
    onGiveToReportBtnClick,
  };
};

export default useSkuListBase;
