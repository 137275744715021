import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";

import {ACTION_TYPES} from "../ActionDependentComponents/constants";

const useBorderColor = () => {
  const location = useLocation();
  const [color, setColor] = useState("#a3b9d9");

  const actionsColors = {
    [ACTION_TYPES.takeToReport]: "#a3b9d9",
    [ACTION_TYPES.returnFromReport]: "rgba(190, 166, 222, 1)",
    [ACTION_TYPES.giveToReport]: "rgba(166, 218, 222, 1)",
    [ACTION_TYPES.restoreFromReport]: "rgba(79, 149, 255, 1)",
    [ACTION_TYPES.toWarehouse]: "rgba(159, 192, 148, 1)",
    [ACTION_TYPES.inventory]: "rgba(224, 201, 2, 0.5)",
    [ACTION_TYPES.forWriteOffWarehouse]: "rgba(254, 27, 27, 0.4)",
  };

  useEffect(() => {
    const actionPath = location.pathname.split("/").reverse()[0];
    setColor(actionsColors?.[actionPath] || "#a3b9d9");
  }, [location]);

  return color;
};

export default useBorderColor;
