import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {Form, Formik} from "formik";
import moment from "moment";
import * as Yup from "yup";

import {apiUrl} from "../../api/baseURL";
import {
  addSampleToSet,
  cleanUpCurrent,
  cleanUpMySamples,
  fetchByQR,
  getCurrentEntity,
  getCurrentQrCode,
  takeKitUnderReport,
  takeUnderReport,
} from "../../slices/SampleSlice";
import {getCurrentUser} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import DateInput from "../../utilities/Inputs/DateInput";
import TextAreaInput from "../../utilities/Inputs/TextAreaInput";
import TextInput from "../../utilities/Inputs/TextInput";
import {ROUTES} from "../../utilities/routes";
import {getInfo} from "../../utilities/toasts";
import ImgBox from "../ImgBox/ImgBox";

import "./main.scss";

export const SAMPLES_INFO_VARIANTS = Object.freeze({KIT: "kit", SAMPLE: "sample"});

const SamplesInfo = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const qrUrl = location?.state?.qrUrl || null;

  const currentQr = useSelector(getCurrentQrCode);
  const current = useSelector(getCurrentEntity);
  const currentUser = useSelector(getCurrentUser);

  const imageMass = useMemo(() => current?.images?.map(img => img.imageUrl) || [], [current]);

  const [isTakeUnderReportBtnDisabled, setIsTakeUnderReportBtnDisabled] = useState(
    !current || current?.managerId === currentUser?.userId,
  );
  const [variant, setVariant] = useState(null);

  useEffect(() => {
    if (current) {
      setVariant(current.type === "sample" ? SAMPLES_INFO_VARIANTS.SAMPLE : SAMPLES_INFO_VARIANTS.KIT);
    }
  }, [current?.type]);

  const initialValues = useMemo(
    () => ({
      skuNumber: "",
      dateTo: "",
      name: "",
      collection: "",
      color: "",
      note: "",
      createdAt: "",
    }),
    [],
  );

  useEffect(() => {
    if (qrUrl && !current) getSampleInfoByQR(qrUrl);

    return () => {
      dispatch(cleanUpCurrent());
      dispatch(cleanUpMySamples());
    };
  }, []);

  const getSampleInfoByQR = url => {
    // dispatch(fetchByQR(url));

    if (url.includes(apiUrl)) {
      dispatch(fetchByQR(url));
    } else getInfo("Такого QR коду немає в базі");
  };

  const onBackBtnClick = () => {
    dispatch(cleanUpCurrent());
    dispatch(cleanUpMySamples());
    navigate(ROUTES.SCANER);
  };

  const onTakeUnderReport = values => {
    const {dateTo, note} = values;
    if (current?.type === "sample") {
      dispatch(takeUnderReport({sampleId: current.sampleId, data: {dateTo, note}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          setIsTakeUnderReportBtnDisabled(true);
        }
      });
    } else {
      dispatch(takeKitUnderReport({kitId: current.kitId, data: {dateTo, note}})).then(resp => {
        if (resp.meta.requestStatus === "fulfilled") {
          setIsTakeUnderReportBtnDisabled(true);
        }
      });
    }
  };

  // const onScanBtnClick = () => {
  //   navigate(ROUTES.SCANER);
  //   dispatch(deselectSample());
  //   dispatch(cleanUpMySamples());
  // };
  const addToSet = () => {
    const currentEntity = {
      id: current[`${variant === SAMPLES_INFO_VARIANTS.KIT ? "kit" : "sample"}Id`],
      sampleId: current?.sampleId,
      sampleKitId: current?.kit?.kitId,
      kitId: current?.kit ? undefined : current?.kitId,
      image: current.images[0],
    };
    dispatch(addSampleToSet(currentEntity));
    dispatch(cleanUpCurrent());
    dispatch(cleanUpMySamples());
    navigate(ROUTES.SCANER);
  };

  return (
    <>
      <Formik
        initialValues={
          current
            ? {...current, createdAt: "[" + moment(current?.createdAt).format("DD.MM.YY  HH:mm:ss") + "]"}
            : initialValues
        }
        validationSchema={Yup.object().shape({
          note: Yup.string().required(t("Enter note")).nullable(),
          dateTo: Yup.date().required(t("Enter date")).nullable(),
        })}
        validateOnChange={true}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={onTakeUnderReport}
      >
        <Form className="Samplies_MOB_Manager_form ">
          <div className="qr_code_container fcCol gap aic jcc">
            {currentQr ? (
              <div className="sample_photo_container">
                <ImgBox imgs={imageMass} width={"180px"} height={"180px"} />
              </div>
            ) : (
              <div className="sample_photo_container"></div>
            )}

            {/* <FunctionButton name="Scan" className="db centered bigBtn" onClick={onScanBtnClick} /> */}
          </div>
          <div className="flexG fcCol jcsa aife w100">
            <div className="fcCol jcsa aife w100">
              <div className="fc nowrap w100 jcsb">
                {variant === SAMPLES_INFO_VARIANTS.SAMPLE && (
                  <TextInput
                    label={t("SKU №:")}
                    name={"skuNumber"}
                    width={"100%"}
                    readOnly
                    inputclassname="skuInput"
                    // labelStyles={{width: "100px"}}
                    style={{width: "calc(100% - 11em)"}}
                  />
                )}
                {variant === SAMPLES_INFO_VARIANTS.KIT && (
                  <TextInput
                    label={t("kitSKU №:")}
                    name={"skuNumber"}
                    width={"100%"}
                    readOnly
                    inputclassname="skuInput"
                    style={{width: "calc(100% - 11em)"}}
                  />
                )}
                <TextInput name={"createdAt"} style={{width: "9.8em"}} readOnly />
              </div>

              {/* <TextInput label={t("nomination")} name={"name"} width={"100%"} readOnly inputclassname="skuInput" /> */}
              <TextAreaInput
                label={t("nomination")}
                name={"name"}
                width={"100%"}
                height="70px"
                readonly={true}
                inputclassname="skuInput"
              />
              {variant === SAMPLES_INFO_VARIANTS.SAMPLE && (
                <>
                  <TextInput
                    label={t("Collection") + ":"}
                    name={"collection"}
                    labelStyles={{width: "100px"}}
                    style={{width: "100%"}}
                    className="mb"
                    width={"100%"}
                    readOnly
                    inputclassname="skuInput"
                  />
                  <TextInput
                    label={t("Color") + ":"}
                    name={"color"}
                    style={{width: "100%"}}
                    width={"100%"}
                    className="mb"
                    labelStyles={{width: "100px"}}
                    readOnly
                    inputclassname="skuInput"
                  />
                  <TextInput
                    label={t("Texture") + ":"}
                    name={"texture.textureName"}
                    labelStyles={{width: "100px"}}
                    width={"100%"}
                    style={{width: "100%"}}
                    className="mb"
                    readOnly
                    inputclassname="skuInput"
                  />
                  <TextInput
                    label={t("Material") + ":"}
                    name={"material.materialName"}
                    labelStyles={{width: "100px"}}
                    width={"100%"}
                    style={{width: "100%"}}
                    className="mb"
                    readOnly
                    inputclassname="skuInput"
                  />
                </>
              )}
            </div>

            <DateInput
              label={t("Підзвіт ДО:")}
              name="dateTo"
              isRowLayout
              className="w100 jcc mb5 "
              generalClassName="alsc"
              style={{gap: "1rem", alignSelf: "center"}}
              flatpikrConfig={{minDate: "today"}}
              readOnly={isTakeUnderReportBtnDisabled}
            />
            <TextAreaInput
              name={"note"}
              placeholder={t("Note") + "*"}
              height="65px"
              // style={{pad  ding: "7px"}}
              readonly={isTakeUnderReportBtnDisabled}
            />
          </div>
          <div className="fc jcsb contr_btns" style={{width: "100%"}}>
            <FunctionButton
              name={isTakeUnderReportBtnDisabled ? "Taken in report" : "Take in report"}
              type="submit"
              disabled={isTakeUnderReportBtnDisabled}
              className="bigBtn"
            />
            <FunctionButton name="Ex" onClick={onBackBtnClick} className="centered bigBtn" />
            <FunctionButton name="Додати до серії" onClick={addToSet} className="centered bigBtn" />
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default SamplesInfo;
