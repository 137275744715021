import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useFormikContext} from "formik";

import {
  cleanUpCurrent,
  fetchByQR,
  getCurrentActionType,
  getCurrentEntity,
  getMySamples,
  restoreSample,
  returnToBrandManagerSample,
  selectSampleLoading,
  takeUnderReportSample,
  transferToWarehouseForWriteOffSample,
  transferToWarehouseSample,
} from "../../../slices/SampleSlice";
import {getCurrentUser, selectAllUsers} from "../../../slices/UserSlice";
import {dateFormat} from "../../../utilities/dateFormat";
import {ROUTES} from "../../../utilities/routes";
import {toaster} from "../../../utilities/toaster";
import useColumns from "../../../utilities/useColumns";
import {NO_NOTE_ACTIONS} from "../constants";

import {RESTORE_SAMPLE_FROM_REPORT_TABLE_COLUMNS} from "./common/columns";

const useSampleActionBase = ({actionType}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {values, setValues} = useFormikContext();
  const {dateTo, note, reportForManagerId} = values;

  const loading = useSelector(selectSampleLoading);
  const currentSample = useSelector(getCurrentEntity);
  const currentUser = useSelector(getCurrentUser);
  const currentUserSamples = useSelector(getMySamples);
  const allUsers = useSelector(selectAllUsers),
    currentActionType = useSelector(getCurrentActionType);

  const images = currentSample?.images?.map(image => image.imageUrl) ?? [];
  const warehouseOptions = []; //!No functional
  const managerOptions =
    allUsers?.map(({userId, firstName, lastName}) => ({value: userId, title: `${firstName} ${lastName}`})) ?? [];

  const location = useLocation();
  const scannedQr = location.state?.qrUrl;

  useEffect(() => {
    if (scannedQr && !currentSample) {
      dispatch(fetchByQR(scannedQr));
    }
  }, [scannedQr]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem(
        "actionInputData",
        JSON.stringify({
          note: values.note,
          dateTo: values.dateTo,
          reportForManagerId: values.reportForManagerId,
        }),
      );
    };
  }, [values.note, values.dateTo, values.reportForManagerId]);

  useEffect(() => {
    if (currentSample && currentSample.type === "sample")
      try {
        const {
          note: savedNote,
          dateTo: savedDateTo,
          reportForManagerId: savedReportForManagerId,
        } = JSON.parse(sessionStorage.getItem("actionInputData")) ?? {};

        setValues({
          status: currentSample?.status?.name ?? "",
          skuNumber: currentSample?.skuNumber ?? "",
          title: currentSample?.name ?? "",
          dateTo: currentSample?.dateTo ?? savedDateTo ?? "",
          note: (NO_NOTE_ACTIONS.includes(actionType) ? currentSample?.note : savedNote) ?? "",
          collection: currentSample?.collection ?? "",
          color: currentSample?.color ?? "",
          texture: currentSample?.texture?.textureName ?? "",
          material: currentSample?.material?.materialName ?? "",
          reportManager: currentSample?.manager
            ? `${currentSample?.manager?.firstName} ${currentSample?.manager?.lastName}`
            : "",
          manager: `${currentUser?.firstName} ${currentUser?.lastName}` ?? "",
          brandManager: `${currentSample?.brandManager?.firstName} ${currentSample?.brandManager?.lastName}` ?? "",
          inventoryDate: new Date().toDateString(),
          writeOffDate: new Date().toDateString(),
          reportForManagerId: savedReportForManagerId ?? "",
        });
      } catch (error) {
        console.error(error);
      }
  }, [currentSample?.sampleId, currentUser?.userId, currentActionType]);

  const restoreFromReportTableColumns = useColumns(RESTORE_SAMPLE_FROM_REPORT_TABLE_COLUMNS);
  const restoreFromReportTableData =
    currentUserSamples?.map(({sampleId, skuNumber, dateTo}, index) => ({
      number: ++index,
      id: sampleId,
      skuNumber,
      dateTo: dateFormat(dateTo) ?? "-",
    })) ?? [];

  function cleanUpAndReturn() {
    dispatch(cleanUpCurrent());
    navigate(ROUTES.SCANER);
  }

  function onTakeToReportBtnClick() {
    if (!currentSample) return;

    dispatch(takeUnderReportSample({dateTo, note, sampleIdList: [currentSample.sampleId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample was taken under report");
        cleanUpAndReturn();
      }
    });
  }

  function onReturnFromReportBtnClick() {
    if (!currentSample) return;

    dispatch(returnToBrandManagerSample({note, sampleIdList: [currentSample.sampleId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample returned to Brand Manager");
        cleanUpAndReturn();
      }
    });
  }

  function onGiveToReportBtnClick() {
    if (!currentSample || !dateTo || !note || !reportForManagerId) return;

    dispatch(
      takeUnderReportSample({dateTo, note, managerId: reportForManagerId, sampleIdList: [currentSample.sampleId]}),
    ).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample was taken under report");
        cleanUpAndReturn();
      }
    });
  }

  function onRestoreFromReportBtnClick() {
    if (!currentSample) return;

    dispatch(restoreSample({note, sampleIdList: [currentSample.sampleId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample restored");
        cleanUpAndReturn();
      }
    });
  }
  function onMoveToWarehouseBtnClick() {
    if (!currentSample) return;

    dispatch(transferToWarehouseSample({note, sampleIdList: [currentSample.sampleId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample transfered to Warehouse");
        cleanUpAndReturn();
      }
    });
  }

  function onWriteOffToWarehouseBtnClick() {
    if (!currentSample) return;

    dispatch(transferToWarehouseForWriteOffSample({note, sampleIdList: [currentSample.sampleId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample transfered to Warehouse for write off");
        cleanUpAndReturn();
      }
    });
  }

  function onVerifyInventoryBtnClick() {
    if (!currentSample) return;

    dispatch(restoreSample({note, sampleIdList: [currentSample.sampleId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample restored");
        cleanUpAndReturn();
      }
    });
  }

  // function onWriteOffBtnClick() {
  //   if (!currentSample) return;

  //   dispatch(writeOffSample({note, sampleIdList: [currentSample.sampleId]})).then(resp => {
  //     if (resp.meta.requestStatus === "fulfilled") {
  //       toaster.success("Sample was written off");
  //       cleanUpAndReturn();
  //     }
  //   });
  // }

  function onTakeToReportRMBtnClick() {
    if (!currentSample || !dateTo || !note) return;

    dispatch(takeUnderReportSample({dateTo, note, sampleIdList: [currentSample.sampleId]})).then(resp => {
      if (resp.meta.requestStatus === "fulfilled") {
        toaster.success("Sample was taken under report");
        cleanUpAndReturn();
      }
    });
  }

  function onGoBackBtnClick() {
    cleanUpAndReturn();
  }

  return {
    images,
    onTakeToReportBtnClick,
    onReturnFromReportBtnClick,
    onRestoreFromReportBtnClick,
    onMoveToWarehouseBtnClick,
    onVerifyInventoryBtnClick,
    // onWriteOffBtnClick,
    onTakeToReportRMBtnClick,
    loading,
    onGiveToReportBtnClick,
    restoreFromReportTableColumns,
    restoreFromReportTableData,
    warehouseOptions,
    managerOptions,
    onGoBackBtnClick,
    onWriteOffToWarehouseBtnClick,
  };
};

export default useSampleActionBase;
