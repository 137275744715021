import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

import {fetchAllUsers, selectAllUsers} from "../../../../slices/UserSlice";
import {Select} from "../../../../utilities/Inputs/SelectField";

const ManagersSelect = () => {
  const dispatch = useDispatch();
  const allUsers = useSelector(selectAllUsers);

  const managerOptions = allUsers
    ?.filter(user => user.role.roleId === 2 || user.role.roleId === 1)
    .map(({userId, firstName, lastName}) => ({value: userId, title: `${firstName} ${lastName}`}));

  useEffect(() => {
    dispatch(fetchAllUsers());
  }, []);

  return (
    <Select
      label={t("Report for Manager")}
      autolabel
      name={"reportForManagerId"}
      width={"100%"}
      options={managerOptions}
    />
  );
};

export default ManagersSelect;
