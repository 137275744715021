export const RESTORE_SAMPLE_FROM_REPORT_TABLE_COLUMNS = Object.freeze([
  {
    header: "№",
    accessorKey: "number",
    size: 10,
  },
  {
    header: "SKU №",
    accessorKey: "skuNumber",
    size: 75,
  },
  {
    header: "Date TO",
    accessorKey: "dateTo",
    size: 75,
  },
]);
