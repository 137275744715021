import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {debounce} from "@mui/material";

import {cleanUpCurrent, deselectSample, fetchByQR, getCurrentEntity} from "../../slices/SampleSlice";
import {getCurrentUser, logout} from "../../slices/UserSlice";
import FunctionButton from "../../utilities/Buttons/FunctionButton";
import {ROUTES} from "../../utilities/routes";
import {getInfo} from "../../utilities/toasts";
import NewQrScaner from "../QRReader/NewQrScaner";

const Scaner = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [qrMessage, setQrMessage] = useState("");
  const [scanerStop, setScanerStop] = useState(false);
  const navigate = useNavigate();
  const current = useSelector(getCurrentEntity);

  const currentUser = useSelector(getCurrentUser);
  // const current = useSelector(getCurrentEntity);

  useEffect(() => {
    if (current && current?.brandManagerId === currentUser?.userId) {
      navigate(ROUTES.SAMPLE_IMAGE, {state: {qrUrl: qrMessage}});
    }
  }, []);

  const fetchInfo = () => {
    dispatch(fetchByQR(qrMessage)).then(res => {
      if (res.meta.requestStatus === "fulfilled") {
        const current = res.payload;
        if (current && current?.brandManagerId === currentUser?.userId) {
          setScanerStop(true);
          navigate(ROUTES.SAMPLE_IMAGE, {state: {qrUrl: qrMessage}});
        } else {
          getInfo("Цей зразок належить іншому менеджеру");
          dispatch(cleanUpCurrent());
        }
      }
    });
  };
  const debouncedFetch = debounce(fetchInfo, 1000);

  useEffect(() => {
    if (qrMessage && qrMessage !== "") {
      debouncedFetch();
    }
  }, [qrMessage]);

  function onLogout() {
    navigate(ROUTES.SCANER);
  }

  return (
    <div className="fc aic jcc h100">
      <div className="centeredFull qrContainer fc aic jcc">
        <div style={{height: "80vh"}}>
          <NewQrScaner resultMessage={qrMessage} setResultMessage={setQrMessage} stop={scanerStop} />
        </div>
        <div className="qr-reader-title">Сканер фотообліку</div>
      </div>
      <div className="fc g1 aic jcc" style={{position: "absolute", bottom: "1em", right: "5px"}}>
        <FunctionButton name="Змінити сканер" onClick={onLogout} className="centered bigBtn" />
      </div>
    </div>
  );
};

export default Scaner;
